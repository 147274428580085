var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-carousel', {
    attrs: {
      "cycle": "",
      "hide-delimiters": "",
      "show-arrows": false,
      "dark": "",
      "height": "100%"
    },
    model: {
      value: _vm.slideNum,
      callback: function callback($$v) {
        _vm.slideNum = $$v;
      },
      expression: "slideNum"
    }
  }, _vm._l(_vm.slides, function (slide, i) {
    return _c('v-carousel-item', {
      key: i,
      staticClass: "card__zindex"
    }, [_c('v-row', {
      staticClass: "fill-height",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "color": "transparent",
        "elevation": "0",
        "max-width": "600"
      }
    }, [_c('v-img', {
      staticClass: "justify-center",
      attrs: {
        "src": require('../../../assets/images/' + slide.image),
        "max-height": "100%"
      }
    }), _c('v-card-title', {
      staticClass: "\n              text-h5\n              font-weight-bold\n              justify-center\n              campaign_slide__title\n              mt-10\n            "
    }, [_vm._v(" " + _vm._s(slide.title) + " ")]), _c('v-card-subtitle', {
      staticClass: "text-center text-body-1 px-3"
    }, [_vm._v(" " + _vm._s(slide.subtitle) + " ")]), _c('v-card-actions', {
      staticClass: "justify-center"
    }, [_vm.slides.length > 1 ? _c('v-item-group', {
      staticClass: "text-center",
      attrs: {
        "mandatory": ""
      },
      model: {
        value: _vm.slideNum,
        callback: function callback($$v) {
          _vm.slideNum = $$v;
        },
        expression: "slideNum"
      }
    }, _vm._l(_vm.slides.length, function (n) {
      return _c('v-item', {
        key: "btn-".concat(n - 1),
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(_ref) {
            var active = _ref.active,
                toggle = _ref.toggle;
            return [_c('v-btn', {
              attrs: {
                "input-value": active,
                "dark": "",
                "icon": ""
              },
              on: {
                "click": toggle
              }
            }, [_c('v-icon', {
              attrs: {
                "color": _vm.slideNum === n - 1 ? '#2EAC68' : '#FFF'
              }
            }, [_vm._v(" " + _vm._s(_vm.mdiRecord) + " ")])], 1)];
          }
        }], null, true)
      });
    }), 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }