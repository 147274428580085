<template>
  <v-carousel
    v-model="slideNum"
    cycle
    hide-delimiters
    :show-arrows="false"
    dark
    height="100%"
  >
    <v-carousel-item v-for="(slide, i) in slides" :key="i" class="card__zindex">
      <v-row align="center" justify="center" class="fill-height">
        <v-col>
          <v-card
            color="transparent"
            elevation="0"
            class="mx-auto"
            max-width="600"
          >
            <v-img
              :src="require('../../../assets/images/' + slide.image)"
              max-height="100%"
              class="justify-center"
            />

            <v-card-title
              class="
                text-h5
                font-weight-bold
                justify-center
                campaign_slide__title
                mt-10
              "
            >
              {{ slide.title }}
            </v-card-title>

            <v-card-subtitle class="text-center text-body-1 px-3">
              {{ slide.subtitle }}
            </v-card-subtitle>
            <v-card-actions class="justify-center">
              <v-item-group
                v-model="slideNum"
                class="text-center"
                mandatory
                v-if="slides.length > 1"
              >
                <v-item
                  v-for="n in slides.length"
                  :key="`btn-${n - 1}`"
                  v-slot="{ active, toggle }"
                >
                  <v-btn :input-value="active" dark icon @click="toggle">
                    <v-icon :color="slideNum === n - 1 ? '#2EAC68' : '#FFF'">
                      {{ mdiRecord }}
                    </v-icon>
                  </v-btn>
                </v-item>
              </v-item-group>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { mdiRecord } from "@mdi/js";
export default {
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      slideNum: 0,
      mdiRecord,
    };
  },
};
</script>
<style scoped>
.card__zindex {
  z-index: 99;
}
.campaign_slide__title {
  color: #2eac68;
}
</style>
